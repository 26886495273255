<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top">
        <div class="el-title-item">
          <el-form ref="form" label-width="80px">
            <el-form-item label="">
              <el-button type="primary" size="small" @click="addDevice"
                >添加设备</el-button
              >
            </el-form-item>
            <el-form-item label="" style="margin-left: 2vw">
              <el-input
                v-model="searchName"
                placeholder="请输入设备名称"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-button
          style="margin-left: 1vw"
          type="primary"
          size="small"
          @click="search"
          >搜索</el-button
        >
      </div>
    </div>
    <el-table
      v-loading="loading"
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%; margin-top: 1vh"
    >
      <el-table-column
        show-overflow-tooltip
        label="序号"
        align="center"
        :index="indexMethod"
        width="100"
        type="index"
      ></el-table-column>
      <el-table-column
        prop="deviceName"
        label="设备名称"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="deviceNum"
        label="设备编号"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        prop="deviceType"
        label="设备类型"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.deviceType == 1">气象监测设备</span>
          <span v-if="scope.row.deviceType == 2">灌溉控制设备</span>
          <span v-if="scope.row.deviceType == 3">土壤墒情设备</span>
          <span v-if="scope.row.deviceType == 4">水质监测设备</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="300">
        <template slot-scope="scope">
          <el-button @click="detail(scope.row)" type="text" size="small"
            >详情</el-button
          >
          <el-button @click="edit(scope.row)" type="text" size="small"
            >编辑</el-button
          >
          <el-button @click="del(scope.row)" type="text" size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 添加修改 -->
    <el-dialog
      title=""
      :visible.sync="messageBox"
      width="31vw"
      class="addAlameBox"
      @close="closeAddForm"
    >
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span
          style="
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            font-size: 2.22vh;
          "
        ></span>
      </div>
      <div class="addAlaForm">
        <el-form
          ref="addForm"
          :rules="rules"
          :model="addForm"
          label-width="10vw"
        >
          <el-form-item label="设备名称" prop="deviceName">
            <el-input
              v-model="addForm.deviceName"
              placeholder="请输入设备名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备编号" prop="deviceNum">
            <el-input
              v-model="addForm.deviceNum"
              placeholder="请输入设备编号"
            ></el-input>
          </el-form-item>
          <el-form-item label="设备类型" prop="deviceType">
            <el-select
              v-model="addForm.deviceType"
              placeholder="请选择设备类型"
            >
              <el-option
                v-for="(item, i) in deviceTypeList"
                :key="i"
                :label="item.name"
                :value="item.id"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="设备位置" prop="location">
            <el-input v-model="addForm.location"></el-input>
          </el-form-item>
          <set-Map ref="mapPotion" @getDatas="getDatas"></set-Map>
        </el-form>
        <div
          slot="footer"
          class="dialog-footer"
          v-show="showFlag"
          style="margin-top: 2vh"
        >
          <el-button type="info" @click="messageBox = false">取消</el-button>
          <el-button type="primary" @click="saveForm('addForm')"
            >确定</el-button
          >
        </div>
      </div>
    </el-dialog>
    <!--    抽屉-->
    <div class="drawerBox">
      <el-dialog
        title="详情"
        :visible.sync="drawer"
        :direction="direction"
        :destroy-on-close="true"
        class="addAlameBox"
      >
        <div class="drawer">
          <h2>设备基础信息</h2>
          <el-row v-if="details">
            <el-col :span="8"
              ><p>设备名称：{{ details.deviceName }}</p></el-col
            >
            <el-col :span="8"
              ><p>序列号：{{ details.deviceNumber }}</p></el-col
            >
            <el-col :span="8"
              ><p>
                在线状态：{{ details.onLineState == 0 ? "离线" : "在线" }}
              </p></el-col
            >
          </el-row>
          <h2>检测数据</h2>
          <el-row>
            <el-col :span="6" v-for="(i, index) in combinedArray" :key="index">
              <p>{{ i.label + "：" + i.value }}</p>
            </el-col>
          </el-row>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import setMap from "../../../components/setMap.vue";
import initMarkerMap from "../../../components/initMarkerMap.vue";
import Videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-contrib-hls";

export default {
  components: { setMap, initMarkerMap },
  data() {
    return {
      details: null,
      combinedArray: [],
      videoHeight: "100vh",
      fullscreen: false,
      isState: true,
      colorList: {
        在线: "green",
        离线: "red",
      },
      deviceTypeList: [
        { id: "1", name: "气象监测设备" },
        { id: "2", name: "灌溉控制设备" },
        { id: "3", name: "土壤墒情设备" },
        { id: "4", name: "水质监测设备" },
      ],
      options: {
        autoplay: true, // 设置自动播放
        muted: true, // 设置了它为true，才可实现自动播放,同时视频也被静音 （Chrome66及以上版本，禁止音视频的自动播放）
        preload: "auto", // 预加载
        controls: true, // 显示播放的控件
      },
      tableData: [],
      page: 1,
      size: 100,
      total: 0,
      loading: false,
      formTitle: "",
      messageBox: false,
      cameraOwn: [
        {
          id: 1,
          name: "海康",
        },
        {
          id: 2,
          name: "大华",
        },
      ],
      showFlag: false,
      ids: 0,
      searchName: "",
      isCapture: true,
      addForm: {
        id: "",
        deviceName: "",
        position: "",
        deviceNumber: "",
        channel: "",
        verifyCode: "",
        brand: "",
        deviceType: "1",
        longitude: "",
        latitude: "",
        capture: 1, //是否截图 0 不截图 1 截图
        data7: "",
      },
      addForms: { position: "" },
      rules: {
        deviceNumber: [
          {
            required: true,
            message: "设备编号不可为空",
            trigger: ["blur", "change"],
          },
        ],
        deviceName: [
          {
            required: true,
            message: "设备名称不可为空",
            trigger: ["blur", "change"],
          },
        ],
        channel: [
          {
            required: true,
            message: "不可为空",
            trigger: ["blur", "change"],
          },
        ],
        verifyCode: [
          {
            required: true,
            message: "不可为空",
            trigger: ["blur", "change"],
          },
        ],
        brand: [
          {
            required: true,
            message: "不可为空",
            trigger: ["blur", "change"],
          },
        ],
        longitude: [{ required: true, message: "不可为空", trigger: "change" }],
        latitude: [{ required: true, message: "不可为空", trigger: "change" }],
        data7: [{ required: true, message: "不可为空", trigger: "change" }],
        deviceType: [
          {
            required: true,
            message: "请选择设备类型",
            trigger: ["blur", "change"],
          },
        ],
      },
      direction: "rtl",
      drawer: false,
      msgs: [],
      showData: [],
      center1: [],
      data: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    changeType(val) {
      //console.log("类型", val);
      this.data = val;
      this.deviceType = this.data;
    },
    //请求列表数据
    getList() {
      this.$get("/sfsbgl/page", {
        page: this.page,
        limit: this.size,
        deviceName: this.searchName,
      }).then((res) => {
        if (res.data.state == "success") {
          this.tableData = res.data.data;
          this.total = res.data.count;
          //console.log("请求列表数据", this.tableData);
        }
        setTimeout(() => {
          this.loading = false;
        }, 500);
      });
    },

    // 摄像头厂家
    changeCamera(val) {
      this.addForm.cameraType = val.id;
      this.addForm.brand = val.name;
    },
    // 获取地图组件数据
    getDatas(e) {
      this.addForm.location = e;
      this.$forceUpdate();
    },
    // 是否截屏
    changeCapture(val) {
      val == true ? (this.addForm.capture = 1) : (this.addForm.capture = 0);
      //console.log(this.addForm.capture);
      //console.log("0不截,1截");
    },
    // 序号
    indexMethod(index) {
      return index + this.size * (this.page - 1) + 1;
    },
    //确认提交
    saveForm(formName) {
      let url;
      if (this.formTitle == "新增") {
        url = "/sfsbgl/save";
      } else if (this.formTitle == "编辑") {
        url = "/sfsbgl/save";
        //console.log("this.addForm", this.addForm);
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$postJSON(url, {
            id: this.ids,
            deviceNum: this.addForm.deviceNum,
            deviceName: this.addForm.deviceName,
            deviceType: this.addForm.deviceType,
            location: this.addForm.location,
          })
            .then((res) => {
              if (res.data.state == "success") {
                this.$message.success("操作成功");
                this.messageBox = false;
                this.getList();
              } else {
                this.$message.error(res.data.msg);
                this.messageBox = false;
              }
            })
            .catch((err) => {
              this.$message.error(err.data.msg);
            });
        } else {
          //console.log("error submit!!");
          return false;
        }
      });
    },
    // 新增
    addDevice() {
      this.formTitle = "新增";
      this.messageBox = true;
      this.showFlag = true;
      this.isCapture = true;
      this.ids = 0;
      this.$nextTick(() => {
        this.addForm = {};
        this.addForm.capture = 1;
        this.$refs.addForm.resetFields();
        this.$refs.mapPotion.setMap1(116.06, 35.6);
      });
    },
    //编辑
    edit(item) {
      //console.log("item", item);
      this.ids = item.id;
      this.showFlag = true;
      this.formTitle = "编辑";
      this.messageBox = true;
      let that = this;
      this.$nextTick(() => {
        that.addForm = JSON.parse(JSON.stringify(item));
        that.$refs.mapPotion.setMap1(
          item.location.split(",")[0],
          item.location.split(",")[1]
        );
      });

      this.addForm.capture == 1
        ? (this.isCapture = true)
        : (this.isCapture = false);
    },
    // 详情
    detail(item) {
      this.ids = item.id;
      this.title = "详情";
      this.drawer = true;
      this.showData = this.tableData;
      //console.log(item)
      this.$get("/jxct/getDeviceInfo", {
        deviceNumber: item.deviceNum,
      }).then((res) => {
        if (res.data.state == "success") {
          this.details = res.data.data;
          this.$set(this.details,'deviceName',item.deviceName)
          let type = this.details.type.split("/");
          let data = this.details.data.split("|");
          let combinedArray = type.map((label, index) => ({
            label: label.trim(), // 移除可能存在的空格
            value: data[index].trim(), // 移除可能存在的空格
          }));
          //console.log(combinedArray);
          this.combinedArray = combinedArray;
        }
      });
    },

    //删除

    del(item) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$delete(`/sfsbgl/delete/${item.id}`).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.info("删除失败");
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //关闭dialog
    closeAddForm() {
      this.resetForm(this.addForm);
    },
    // 搜索
    search() {
      this.page = 1;
      this.getList(name);
    },

    handleClose() {
      // this.drawer = false;
      if (this.player) {
        this.player.dispose(); // 该方法会重置videojs的内部状态并移除dom
      }
    },
    handleCloses() {
      this.messageBox = false;
      // 销毁地图
      done();
    },
    // 详情抽屉关闭
    handleClosed(done) {
      done();
    },

    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
    positionObtain(val) {
      let data = val;
      this.addForm.position = data;
    },
    searchMap() {
      let _this = this;
      AMap.service(["AMap.PlaceSearch"], function () {
        var address = _this.locationsss;
        //构造地点查询类
        var placeSearch = new AMap.PlaceSearch({
          pageSize: 5, // 单页显示结果条数
          pageIndex: 1, // 页码
          //            city: "010", // 兴趣点城市
          citylimit: true, //是否强制限制在设置的城市内搜索
          map: mapTwo, // 展现结果的地图实例
          //               panel: "msg", // 结果列表将在此容器中进行展示。
          autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
        });
        //关键字查询
        placeSearch.search(address);
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    requestFullscreen() {
      this.fullscreen = !this.fullscreen;
      this.videoHeight = "80vh";
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

#content /deep/ .el-table::-webkit-scrollbar {
  display: none;
}

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
}

.dialog-footer /deep/ .el-button,
.el-button--info {
  margin-left: 10px !important;
}

.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}

.drawerBox /deep/ .msg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vh 1vw;
  height: 50vh;
}

.cell /deep/.el-button--small {
  color: #409eff;
}

.videoMsg {
  position: relative;
}

.videoBtn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.drawer p{
  line-height: 36px;
}
</style>